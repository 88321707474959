import React from 'react';

function StaticPage() {
    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <iframe
                src={`${process.env.PUBLIC_URL}/index.html`}
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Static Page"
            />
        </div>
    );
}

export default StaticPage;